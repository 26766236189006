<template>
  <div class="cts_box">
    <div class="acc_menu post_form post_comment_form">
      <h3 class="min_ttl acc_tab" v-bind:class="{'open': edit_comment.edit_mode}" v-on:click="comment.edit_mode = false"><span>コメントの投稿</span></h3>

      <div class="acc_cts post_form_inner">
        <form action="">
          <vue-editor ref="editor" v-model="edit_comment.body" :editorOptions="editorSettings"/>

          <div class="option-menu">
            <label class="add-temp-file">
              <input type="file" accept="image/png,image/jpeg,application/pdf,video/mp4,audio/mpeg,audio/m4a,audio/x-m4a"
                     id="post-post-file"
                     style="display: none" v-on:change="postFileSelected($event)"/>
              <span>添付ファイル、動画、音声を追加</span>
            </label>
            <label class="post-date" v-if="isStaff() || $route.query.millioneyes === '2022'">
              <span>投稿日の指定</span>
              <input type="datetime-local" min="1900-01-01T00:00" max="2100-12-31T00:00" v-model="edit_comment.post_date">
            </label>
          </div>
          <div class="input_set addr">
            <validation-errors :errors="errors" v-if="errors"></validation-errors>
          </div>
          <div class="temp_file flex">
            <div class="file_box" v-for="file in edit_comment.files" :key="file.post_file" v-show="!file.remove_post_file">
              <h4>添付ファイル</h4>
              <a v-bind:href="file.post_file_url" target="_blank" _data-lity="data-lity">
                <p class="file_name">{{ file.original_filename }}</p>
              </a>
              <span v-on:click="file.remove_post_file = 1"></span>
            </div>
          </div>

          <div class="post_submit flex">
            <input type="button" class="submit light" value="この内容で投稿する" v-on:click="savePost()">
          </div>
          <div class="post_delete" v-on:click="comment.edit_mode = false"><span>すべての内容を保存せずに削除</span></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import SnsEditor from '@/components/sns/SnsEditor.vue'
import ValidationErrors from '@/components/ValidationErrors.vue'

export default {
  mixins: [Libraries, SnsEditor],
  components: {
    ValidationErrors
  },
  data() {
    this.comment.files.forEach(file => {
      file.remove_post_file = 0
    });
    if (this.isStaff()) {
      this.comment.post_date = ''
    }
    return {
      edit_comment: JSON.parse(JSON.stringify(this.comment)),
      errors: null
    }
  },
  props: {
    post: null,
    comment: null
  },
  methods: {
    // 投稿・コメントへの添付ファイル
    postFileSelected($event) {
      this.startUpload()
      const fileSelectDom = $event.target
      const file = fileSelectDom.files[0]
      this.errors = null
      this.axios
          .post(`${this.env.api_base_url}sns/cache_file_s3_direct.json`, {
            name: file.name
          })
          .then(response => {
            // console.log(response.data)
            response.data.remove_post_file = 0
            const upload_data = response.data
            const params = new FormData();
            for (const key in upload_data.upload_fields) {
              console.log(key, upload_data.upload_fields[key]);
              params.append(key, upload_data.upload_fields[key]);
            }
            params.append('file', file)

            // s3 upload
            no_auth = true
            this.axios
                .post(upload_data.upload_url, params)
                .then(response => {
                  console.log(response.data)
                  this.edit_comment.files.push(upload_data)
                })
                .catch((response) => {
                  console.log(response)
                })
                .finally(() => {
                  no_auth = false
                  this.finishUpload()
                })
          })
          .catch((error) => {
            console.log(error.response.data.errors)
            if (error.response.data.errors) {
              this.errors = error.response.data.errors
            }
            this.finishUpload()
          })
    },
    // 投稿する
    savePost() {
      if (!this.edit_comment.body) {
        return
      }
      if (!confirm('この内容でコメントしますか？')) {
        return
      }
      this.startUpload()

      let files = []
      this.edit_comment.files.forEach(file => {
        if (!file.id || file.remove_post_file) {
          files.push({
            id: file.id,
            sns_post_comment_id: this.edit_comment.id,
            content_type: file.content_type,
            original_filename: file.original_filename,
            post_file: file.post_file,
            remove_post_file: file.remove_post_file,
          })
        }
      });

      this.axios
          .post(`${this.env.api_base_url}sns/save_comment.json`, {
            sns_post_comment: {
              id: this.edit_comment.id,
              sns_post_id: this.post.id,
              body: this.edit_comment.body,
              status: 'status_published',
              post_date: this.edit_comment.post_date,
              sns_post_comment_files_attributes: files
            },
          })
          .then(response => {
            //console.log(response.data)
            this.$emit('saved', this.post, this.comment, response.data)
          })
          .catch((response) => {
            console.log(response)
          })
          .finally(() => {
            this.finishUpload()
          })
    }
  }
}
</script>
