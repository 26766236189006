<template>
  <div class="menu_box">
    <div class="menu_btn" v-on:click="showMenu()"></div>
    <ul class="feed_menu" v-bind:class="{open: post.show_menu}">
      <li v-if="post.editable"><a href="javascript:void(0)" v-on:click="$emit('editPost', post)">編集する</a></li>
      <li v-if="notMe() && is_follow === false"><a href="javascript:void(0)"
                                                   v-on:click="follow()">{{ post.user.sns_user_name }}さんをフォロー</a></li>
      <li v-if="notMe() && is_follow === true"><a href="javascript:void(0)"
                                                  v-on:click="unFollow()">{{ post.user.sns_user_name }}さんのフォローを解除</a>
      </li>
      <li v-if="notMe()"><a href="javascript:void(0)" v-on:click="mute()">{{ post.user.sns_user_name }}さんをミュート</a></li>
      <li v-if="notMe()"><a href="javascript:void(0)" v-on:click="block()">{{ post.user.sns_user_name }}さんをブロック</a></li>

      <li><a href="javascript:void(0)" v-on:click="$emit('reportPost', post)">通報する</a></li>
      <li v-if="false"><a href="javascript:void(0)" v-on:click="$emit('hidePost', post)">非表示</a></li>
      <li v-if="post.editable"><a href="javascript:void(0)" v-on:click="$emit('deletePost', post)">投稿を削除</a></li>
      <li v-if="show_permalink_menu">
        <router-link :to="{ name: 'SnsPost', params: {post_id: post.id}}">
          この投稿のURLへ
        </router-link>
      </li>
      <li v-if="!this.isProduction()"><a href="javascript:void(0)" v-on:click="$emit('playedTest', post) ; post.show_menu = false">＊テスト：動画視聴済みへ</a></li>
      <li v-if="!this.isProduction()"><a href="javascript:void(0)" v-on:click="$emit('audioPlayedTest', post) ; post.show_menu = false">＊テスト：音声再生済みへ</a></li>
    </ul>
  </div>
</template>

<script>

import Libraries from '@/components/Libraries'

export default {
  mixins: [Libraries],
  props: {
    post: null,
    show_permalink_menu: null,
  },
  data() {
    return {
      is_follow: null
    }
  },
  methods: {
    showMenu() {
      if (this.is_follow === null) {
        if (this.post.show_menu) {
          this.post.show_menu = false
        } else {
          this.axios
              .get(`${this.env.api_base_url}sns/is_follow.json`, {
                params: {id: this.post.user.id}
              })
              .then(response => {
                this.is_follow = response.data.follow
              })
              .catch((response) => {
                console.log(response)
              })
              .finally(() => {
                this.post.show_menu = true
              })
        }
      } else {
        this.post.show_menu = !this.post.show_menu
      }
    },
    notMe() {
      return this.current_user.id !== this.post.user.id
    },
    follow() {
      this.axios
          .post(`${this.env.api_base_url}sns/follow_user.json`, {
            id: this.post.user.id
          })
          .then(response => {
            this.is_follow = response.data.follow
          })
          .finally(() => {
            this.post.show_menu = false
            setTimeout(() => {
              alert(this.is_follow ? `${this.post.user.sns_user_name} さんをフォローしました。` : `${this.post.user.sns_user_name} さんのフォローを解除しました`);
            }, 100)
          })
    },
    unFollow() {
      this.follow()
    },
    block() {
      if (!confirm(`${this.post.user.sns_user_name} さんをブロックしますか？\nブロックすると${this.post.user.sns_user_name} さんはあなたをフォローできなくなり、投稿も表示されなくなります。\nまた、${this.post.user.sns_user_name} さんからの投稿やその通知は今後表示されません。`)) {
        return
      }
      this.axios
          .post(`${this.env.api_base_url}sns/block.json`, {
            user_id: this.post.user.id
          })
          .finally(() => {
            this.post.show_menu = false
            setTimeout(() => {
              alert(`${this.post.user.sns_user_name} さんをブロックしました。`);
              document.location.reload()
            }, 100)
          })
    },
    mute() {
      if (!confirm(`${this.post.user.sns_user_name} さんをミュートしますか？`)) {
        return
      }
      this.axios
          .post(`${this.env.api_base_url}sns/mute.json`, {
            user_id: this.post.user.id
          })
          .finally(() => {
            this.post.show_menu = false
            setTimeout(() => {
              alert('ミュートしました。');
              document.location.reload()
            }, 100)
          })
    }
  }
}
</script>
