<script>
import {VueEditor, Quill} from 'vue2-editor';
import * as Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import Libraries from '@/components/Libraries.vue'

Quill.register("modules/emoji", Emoji);

export default {
  mixins: [Libraries],
  components: {
    VueEditor,
  },
  mounted() {
    this.$refs.editor.quill.focus()
    this.$refs.editor.quill.getModule('toolbar').addHandler('video', () => {
      const vimeo_id = prompt("vimeo id:")
      if (vimeo_id) {
        const range = this.$refs.editor.quill.getSelection()
        const vimeo_link = `https://vimeo.com/${vimeo_id}`
        //this.$refs.editor.quill.insertText(range, vimeo_link, 'link', vimeo_link)
        this.$refs.editor.quill.insertText(range, vimeo_link)
      }
    })
  },
  data() {
    let last_tool_items = ['link', 'image', 'emoji']
    if (this.isStaff()) last_tool_items.push('video')
    return {
      edit_post: JSON.parse(JSON.stringify(this.post)),
      editorSettings: {
        modules: {
          toolbar: [
            [{header: [1, 2, 3, false]}],
            // [{size: [ 'small', true, 'large', 'huge' ]}],
            ['bold', 'italic', 'underline', 'strike'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'indent': '-1'}, {'indent': '+1'}, {'align': []}],
            [{'color': []}, {'background': []}],
            //[{ 'font': [] }],
            ['blockquote', 'code-block'],
            last_tool_items
          ],
          'emoji-toolbar': true,
          'emoji-textarea': false,
          'emoji-shortname': true,
        },
        placeholder: '投稿内容を入力してください',
        theme: 'snow'
      }
    }
  }
}
</script>
