<template>
  <div>
    <div class="movie_wrap" v-for="file in video_files()" :key="file.id" v-bind:id="'file-' + file.id"
         v-bind:class="{ draft: file.draft, played: file.played}">
      <div class="played-label" v-if="file.played">視聴済み</div>
      <div class="published_at" v-if="file.draft"> ※投稿:{{ moment(file.published_at, 'YYYY/MM/DD HH:mm') }}</div>
      <div class="video-tag" v-if="file.video_tag" v-html="vimeoTag(file)"></div>
      <div class="video-tag pending" v-else>
        <p>動画を処理中です。しばらくお待ちください。</p>
      </div>
    </div>

    <div class="mp3_wrap" v-for="file in audio_files()" :key="file.id" v-bind:id="'file-' + file.id"
         v-bind:class="{ draft: file.draft }">
      <div class="mp3_name">
        {{ file.original_filename }}
        <span class="published_at" v-if="file.draft"> ※投稿:{{ moment(file.published_at, 'YYYY/MM/DD HH:mm') }}</span>
      </div>
      <audio v-bind:src="file.post_file_url" controls="" controlslist="nodownload"
             oncontextmenu="return false;"
             @timeupdate="audioTimeupdate($event, file)"
             style="width: 100%;"></audio>
    </div>

    <div class="temp_file flex" v-if="pdf_files().length || image_files().length">
      <a v-bind:href="attachment_url(file)" class="file_box" target="_blank" _data-lity="data-lity"
         v-for="file in pdf_files()"
         :key="file.id" v-bind:id="'file-' + file.id"
         v-bind:class="{ draft: file.draft }">
        <h4>PDF資料ファイル</h4>
        <p class="file_name">{{ file.original_filename }}</p>
        <p class="published_at">{{ file.published }}</p>
        <p class="published_at" v-if="file.draft">※投稿:{{ moment(file.published_at, 'YYYY/MM/DD HH:mm') }}</p>
      </a>
      <a v-bind:href="file.post_file_url" class="file_box" target="_blank" _data-lity="data-lity"
         v-for="file in image_files()"
         :key="file.id" v-bind:id="'file-' + file.id"
         v-bind:class="{ draft: file.draft }">
        <h4>画像ファイル</h4>
        <p class="file_name">{{ file.original_filename }}</p>
        <p class="published_at" v-if="file.draft">※投稿:{{ moment(file.published_at, 'YYYY/MM/DD HH:mm') }}</p>
      </a>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  props: {
    files: null,
  },
  mounted() {
  },
  methods: {
    // 投稿ファイルの操作関連
    attachment_url(file) {
      return `${this.env.api_base_url}attachment/${encodeURIComponent(file.original_filename)}?url=${encodeURIComponent(file.post_file_url)}`
    },
    pdf_files() {
      return this.content_type_files('content_type_pdf')
    },
    audio_files() {
      return this.content_type_files('content_type_audio')
    },
    video_files() {
      return this.content_type_files('content_type_video')
    },
    image_files() {
      return this.content_type_files('content_type_image')
    },
    content_type_files(content_type) {
      let files = []
      this.files.forEach(file => {
        if (file.content_type === content_type) {
          files.push(file)
        }
      });
      return files
    },
    // vimeo tag iframe に id を付けて返還
    vimeoTag(file) {
      const vimeo_id = file.video_tag.match(/video\/([0-9]+)/)[1]
      return file.video_tag.replace(/<iframe/, `<iframe id="vimeo-${vimeo_id}"`)
    },

    audioTimeupdate($event, file) {
      const audio = $event.target
      const percent = audio.currentTime / audio.duration
      // console.log(percent, file.id)
      if (file.percent < 0.6 && percent >= 0.6) {
        this.axios
            .post(`${this.env.api_base_url}sns/audio_play.json`, {
              sns_post_file_id: file.id,
            })
            .catch((response) => {
              console.log(response)
            })
      }
      file.percent = percent
    }
  }
}
</script>

<style scoped>
.pending {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 2em;
}
</style>
