<template>

  <div v-bind:class="{'staff-post': isStaff()}">

    <div class="staff-icon blink" v-if="isStaff()">
      <img v-bind:src="current_user.profile_image_url" alt="">
      <span>{{ current_user.sns_user_name }}</span>
    </div>

    <div class="acc_cts post_form_inner office_cts_box">

      <form action="">
        <div class="option-menu mb0 mt0">
          <label class="post-title">
            <input type="text" v-model="edit_post.title" placeholder="タイトル(件名)">
          </label>
        </div>
        <vue-editor ref="editor" v-model="edit_post.body" :editorOptions="editorSettings"/>

        <SnsTagForm :sns_tag="edit_post.sns_tag" :tags="tags" v-on:changed="tagChanged"
                    v-if="this.$route.params.nav_category !== 'timeline'"/>

        <div class="option-menu">
          <div class="inbox">
            <label class="add-temp-file">
              <input type="file"
                     accept="image/png,image/jpeg,application/pdf,video/mp4,audio/mpeg,audio/m4a,audio/x-m4a"
                     id="post-post-file"
                     style="display: none" v-on:change="postFileSelected($event)"/>
              <span>添付ファイル、動画、音声を追加</span>
            </label>
          </div>

          <div class="inbox">
            <label class="add-temp-vimeo" v-if="isStaff()">
              <span href="javascript:void(0)" @click="vimeo_id_error = false ; vimeo_id = null ; vimeo_modal=true">vimeoから動画を追加</span>
            </label>
          </div>

          <div class="inbox">
            <label class="move-room" v-if="edit_post.room_moveable"
                   v-on:click="movePost()">
              <span>投稿場所を移動する</span>
            </label>
          </div>

          <div class="inbox">
            <label class="post-date" v-if="isStaff() || $route.query.millioneyes === '2022'">
              <span class="pl0">投稿日の指定</span>
              <input type="datetime-local" min="1900-01-01T00:00" max="2100-12-31T00:00" v-model="edit_post.post_at">
            </label>
          </div>

          <div class="inbox">
          <span class="checkbox c_red pinning" v-if="isStaff()">

            <input id="pinning" type="checkbox" v-model="edit_post.pinning" value="1">
            <label for="pinning"><img src="/common/images/icon/pinning.svg"
                                      style="width: 20px; vertical-align: text-bottom;margin: 0 2px 0 0;">ピン留め</label>
          </span>
          </div>

          <div class="hint size10 alnC" v-if="isStaff()">
            ※ 投稿日は5分刻みで設定してください。動画を添付する際、ページに反映されるまで時間がかかる場合があります。
          </div>
        </div>

        <SnsPostMoveForm :room_id="move_room_id" v-if="show_move_spaces" v-on:change="moveRoomChanged"/>

        <div class="temp_file flex">
          <div class="file_box" v-for="file in edit_post.files" :key="file.post_file" v-show="!file.remove_post_file">
            <h4>添付ファイル</h4>
            <a v-bind:href="file.post_file_url" target="_blank" _data-lity="data-lity">
              <p class="file_name">{{ file.original_filename }}</p>
            </a>
            <span v-on:click="file.remove_post_file = 1"></span>
          </div>
        </div>
        <div class="date_calender flex" v-if="isStaff()">
          <div class="calender">
          </div>
        </div>

        <div class="post_submit flex">
          <input type="button" class="submit light" value="この内容で投稿する" v-on:click="savePost()">
          <input type="button" class="submit light skeleton" value="下書きとして保存"
                 v-if="false" v-on:click="draftSavePost()">
          <!-- !post.id || post.status === 'status_draft' -->
        </div>
        <div class="post_delete" v-on:click="post.edit_mode = false"><span>すべての内容を保存せずに削除</span></div>
      </form>


      <div class="modal" v-bind:class="{open: attachment_modal}" id="staff-modal">
        <div class="modal_box">
          <div class="header">
            <h4>添付ファイルの投稿</h4>
            <div class="close modal_close" v-on:click="attachment_modal = false"></div>
          </div>
          <div class="inner">
            <div class="btn_wrap">
              <a href="javascript:void(0);" class="submit light"
                 v-on:click="attachment_modal = false; postFileUpload(attachment_event)">今すぐアップロード</a>
            </div>
            <div class="btn_wrap">
              <a href="javascript:void(0);" class="submit light"
                 v-on:click="attachment_modal = false; postFileAfterUpload(attachment_event)">投稿時にまとめてアップロード</a>
            </div>

            <div style="display: block;width: 100%;height: 1px; background: #ccc; margin: 20px 0;"></div>

            <div style="text-align: center;">
              <span style="display: block;" class="mb10">投稿日と公開日を別にする場合の日時指定</span>
              <input type="datetime-local" min="1900-01-01T00:00" max="2100-12-31T00:00"
                     v-model="attachment_published_at">
            </div>
            <div class="btn_wrap">
              <a href="javascript:void(0);" class="submit gray modal_close"
                 v-on:click="attachment_modal = false; clearPostFile()">キャンセル</a>
            </div>
          </div>
        </div>
        <div class="ovr"></div>
      </div>

      <div class="modal" v-bind:class="{open: vimeo_modal}">
        <div class="modal_box">
          <div class="header">
            <h4>vimeoから投稿</h4>
            <div class="close modal_close" v-on:click="vimeo_modal = false"></div>
          </div>
          <div class="inner">
            <div style="text-align: center">
              <span style="width: 20%; display: inline-block">投稿日の指定</span>&nbsp;&nbsp;
              <input type="datetime-local" min="1900-01-01T00:00" max="2100-12-31T00:00"
                     v-model="attachment_published_at"
                     style="width: 35%;">
            </div>

            <div style="text-align: center">
              <span style="width: 20%; display: inline-block">vimeo id</span>&nbsp;&nbsp;
              <input type="text" v-model="vimeo_id"
                     style="padding: 6px; margin-top: 20px; height: 40px; font-size: 18px; width: 35%;">
              <p class="red bold mt10" v-if="vimeo_id_error">無効な vimeo idです</p>
            </div>

            <div class="btn_wrap">
              <a href="javascript:void(0);" class="submit light" v-bind:class="{disabled: !vimeo_id}"
                 v-on:click="postFileVimeoUpload()">vimeoから投稿</a>
            </div>
            <div class="btn_wrap">
              <a href="javascript:void(0);" class="submit gray modal_close"
                 v-on:click="vimeo_modal = false; clearPostFile()">キャンセル</a>
            </div>
          </div>
        </div>
        <div class="ovr"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import SnsEditor from '@/components/sns/SnsEditor.vue'
import SnsTagForm from '@/components/sns/SnsTagForm.vue'
import SnsPostMoveForm from '@/components/sns/SnsPostMoveForm.vue'

export default {
  mixins: [Libraries, SnsEditor],
  components: {
    SnsTagForm, SnsPostMoveForm
  },
  data() {
    this.post.files.forEach(file => {
      file.remove_post_file = 0
    });
    if (this.isStaff()) {
      this.post.post_at = ''
    }
    return {
      edit_post: JSON.parse(JSON.stringify(this.post)),
      show_move_spaces: false,
      move_room_id: this.post.sns_space_room_id,
      attachment_modal: false,
      attachment_published_at: null,
      attachment_event: null,

      vimeo_modal: false,
      vimeo_id_error: false,
      vimeo_id: null,

      S3: Object.freeze({
        MAX_SIZE: 1024 * 1024 * 1024 * 5
      })
    }
  },
  props: {
    post: null,
    tags: []
  },
  methods: {

    checkFileSize(file_size) {
      if (file_size > this.S3.MAX_SIZE) {
        if (this.isStaff()) {
          alert('ファイルサイズが大きすぎます。5ギガを超える場合には vimeoの管理画面から登録後に vimeo idから動画を投稿してください。')
        } else {
          alert('ファイルサイズが大きすぎます。')
        }
        return false
      }
      return true
    },

    // 投稿・コメントへの添付ファイル
    postFileSelected($event) {
      const file = $event.target.files[0]
      if (!this.checkFileSize(file.size)) {
        return
      }
      if (this.isStaff()) {
        this.attachment_event = $event
        this.attachment_published_at = null
        this.attachment_modal = true
      } else {
        this.postFileUpload($event)
      }
    },

    postFileAfterUpload($event) {
      const fileSelectDom = $event.target
      const file = fileSelectDom.files[0]
      this.edit_post.files.push({
        content_type: file.type, // content_type_video
        original_filename: file.name,
        post_file_url: null,
        post_file: null,
        file: file,
        remove_post_file: 0,
        published_at: this.attachment_published_at
      })
    },

    postFileUpload($event) {
      this.startUpload()
      const fileSelectDom = $event.target
      const file = fileSelectDom.files[0]
      this.axios
          .post(`${this.env.api_base_url}sns/cache_file_s3_direct.json`, {
            name: file.name
          })
          .then(response => {
            console.log(response.data)
            response.data.remove_post_file = 0
            response.data.published_at = this.attachment_published_at
            const upload_data = response.data
            const params = new FormData();
            for (const key in upload_data.upload_fields) {
              console.log(key, upload_data.upload_fields[key]);
              params.append(key, upload_data.upload_fields[key]);
            }
            params.append('file', file)

            // s3 upload
            no_auth = true
            this.axios
                .post(upload_data.upload_url, params)
                .then(response => {
                  console.log(response.data)
                  this.edit_post.files.push(upload_data)
                })
                .catch((response) => {
                  console.log(response)
                })
                .finally(() => {
                  no_auth = false
                  this.finishUpload()
                  this.clearPostFile()
                })
          })
          .catch((response) => {
            console.log(response)
            this.finishUpload()
            this.clearPostFile()
          })
    },
    clearPostFile() {
      document.getElementById('post-post-file').value = null
    },
    // タグの更新
    tagChanged(sns_tag) {
      this.edit_post.sns_tag = sns_tag;
    },

    // 投稿時にまとめてアップロード とした添付ファイルを同期アップロード
    async postFileAfterUploadExecute(post_file) {
      const file = post_file.file
      let upload_data = null;
      await this.axios.post(`${this.env.api_base_url}sns/cache_file_s3_direct.json`, {name: file.name})
          .then(response => {
            console.log(response.data)
            upload_data = response.data
          })

      const params = new FormData();
      for (const key in upload_data.upload_fields) {
        console.log(key, upload_data.upload_fields[key]);
        params.append(key, upload_data.upload_fields[key]);
      }
      params.append('file', file)

      // s3 upload
      no_auth = true
      await this.axios.post(upload_data.upload_url, params)
          .then(response => {
            console.log(response.data)
            post_file.content_type = upload_data.content_type
            post_file.original_filename = upload_data.original_filename
            post_file.post_file_url = upload_data.post_file_url
            post_file.post_file = upload_data.post_file
          })
          .catch((response) => {
            console.log(response)
          })
          .finally(() => {
          })
          .catch((response) => {
            console.log(response)
          })
      no_auth = false
    },

    // vimeo id から動画を添付
    postFileVimeoUpload() {
      this.startUpload()
      this.axios
          .post(`${this.env.api_base_url}sns/cache_file_vimeo.json`, {
            vimeo_id: this.vimeo_id
          })
          .then(response => {
            console.log(response.data)
            response.data.remove_post_file = 0
            response.data.published_at = this.attachment_published_at
            this.edit_post.files.push(response.data)
            this.vimeo_modal = false
          })
          .catch((response) => {
            console.log(response)
            this.vimeo_id_error = true
          })
          .finally(() => {
            this.finishUpload()
          })
    },

    // 投稿を保存する
    async savePost() {
      if (!this.edit_post.body) {
        return
      }
      const msg = this.isStaff() ? `${this.current_user.sns_user_name} として、この内容を投稿しますか？` : 'この内容で投稿しますか？'
      if (!confirm(msg)) {
        return
      }
      this.startUpload()

      let files = []
      for (let i = 0; i < this.edit_post.files.length; i++) {
        const file = this.edit_post.files[i]
        if (!file.id || file.remove_post_file) {
          // 投稿時にまとめてアップロード を処理
          if (file.file) {
            await this.postFileAfterUploadExecute(file)
            delete file.file
          }

          files.push({
            id: file.id,
            sns_post_id: this.edit_post.id,
            content_type: file.content_type,
            original_filename: file.original_filename,
            post_file: file.post_file,
            vimeo_id: file.vimeo_id,
            remove_post_file: file.remove_post_file,
            published_at: file.published_at,
          })
        }
      }
      const move_room_id = (this.move_room_id !== this.post.sns_space_room_id ? this.move_room_id : null)

      this.axios
          .post(`${this.env.api_base_url}sns/save_post.json`, {
            sns_post: {
              id: this.edit_post.id,
              sns_space_id: this.edit_post.space_id,
              sns_space_room_id: this.edit_post.space_user_id ? null : this.edit_post.room_id,
              space_user_id: this.edit_post.space_user_id,
              title: this.edit_post.title,
              body: this.edit_post.body,
              status: 'status_published',
              sns_tag: this.edit_post.sns_tag,
              post_date: this.edit_post.post_at,
              pinning: this.edit_post.pinning,
              move_room_id: move_room_id,
              sns_post_files_attributes: files
            },
          })
          .then(response => {
            //console.log(response.data)
            this.$emit('saved', this.post, response.data)
          })
          .catch((response) => {
            console.log(response)
          })
          .finally(() => {
            this.finishUpload()
          })
    },

    // 投稿を下書き保存する
    draftSavePost() {
      if (!this.edit_post.body) {
        return
      }
      if (!confirm('この内容で下書き保存しますか？')) {
        return
      }

      let files = []
      this.edit_post.files.forEach(file => {
        if (!file.id || file.remove_post_file) {
          files.push({
            id: file.id,
            sns_post_id: this.edit_post.id,
            content_type: file.content_type,
            original_filename: file.original_filename,
            post_file: file.post_file,
            remove_post_file: file.remove_post_file,
            published_at: file.published_at,
          })
        }
      });

      this.axios
          .post(`${this.env.api_base_url}sns/save_post.json`, {
            sns_post: {
              id: this.edit_post.id,
              sns_space_id: this.edit_post.space_id,
              sns_space_room_id: this.edit_post.space_user_id ? null : this.edit_post.room_id,
              space_user_id: this.edit_post.space_user_id,
              title: this.edit_post.title,
              body: this.edit_post.body,
              status: 'status_draft',
              sns_tag: this.edit_post.sns_tag,
              move_room_id: (this.move_room_id !== this.post.sns_space_room_id ? this.move_room_id : null),
              sns_post_files_attributes: files
            },
          })
          .then(response => {
            //console.log(response.data)
            this.$emit('draft_saved', this.post, response.data)
          })
          .catch((response) => {
            console.log(response)
          })
          .finally(() => {
          })
    },

    // 投稿を移動
    movePost() {
      this.show_move_spaces = !this.show_move_spaces
    },
    moveRoomChanged(room_id) {
      this.move_room_id = room_id
    }
  }
}
</script>

<style lang="scss">
.option-menu {
  margin: 20px 0;

  label.post-title {
    input {
      line-height: normal;
      height: auto;
      padding: 10px 5px;
      margin-bottom: 20px;
    }
  }

  .pinning {
    label {
      font-weight: bold;
      color: #007CCA;
    }
  }

  label {
    span {
      position: relative;
      padding: 0 0 0 30px;
      cursor: pointer;
      font-weight: bold;
      color: #007CCA;
    }

    &.add-temp-file {
      :before {
        content: "";
        width: 24px;
        height: 24px;
        background: url(/common/images/icon/post_menu02.svg) no-repeat center / contain;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    &.add-temp-vimeo {
      :before {
        content: "";
        width: 24px;
        height: 24px;
        background: url(/common/images/icon/post_menu_vimeo.png) no-repeat center / contain;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    &.move-room {
      :before {
        content: "";
        width: 24px;
        height: 24px;
        background: url(/common/images/icon/arrow_back_wht.svg) no-repeat center / contain;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
}
.staff-post {
  .staff-icon{
    margin: 10px 0 10px 0;
    position: relative;
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 5px;
      overflow: hidden;
    }
    span {
      position: absolute;
      left: 70px;
      top: 20px;
    }
  }
  .acc_cts {
    padding: 0;
  }

  .blink {
    animation: blinkAnime 1s infinite alternate;
  }
  @keyframes blinkAnime{
    0% { opacity: 0.5 }
    100% { opacity: 1 }
  }
}

</style>
