<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  data() {
    return {
      new_comment: {},
    }
  },
  methods: {

    // body変換
    bodyEssence(post_body, query = null) {
      // vimeo iframe
      let body = post_body.replace(/<p>https:\/\/vimeo\.com\/manage\/videos\/([a-z0-9]+)\/([a-z0-9]+)/g,
          '<p><div class="vimeo-frame"><iframe id="vimeo-$1" src="https://player.vimeo.com/video/$1?h=$2&amp;badge=0&amp;autopause=0&amp;player_id=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title=""></iframe></div>')
      body = body.replace(/<p>https:\/\/vimeo\.com\/manage\/videos\/([a-z0-9]+)/g,
          '<p><div class="vimeo-frame"><iframe id="vimeo-$1" src="https://player.vimeo.com/video/$1?badge=0&amp;autopause=0&amp;player_id=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title=""></iframe></div>')

      body = body.replace(/<p>https:\/\/vimeo\.com\/event\/([a-z0-9]+)/g,
          '<p><div class="vimeo-frame"><iframe id="vimeo-$1" src="https://vimeo.com/event/$1/embed" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div></p>')

      body = body.replace(/<p>https:\/\/vimeo\.com\/([a-z0-9]+)/g,
          '<p><div class="vimeo-frame"><iframe id="vimeo-$1" src="https://player.vimeo.com/video/$1?badge=0&amp;autopause=0&amp;player_id=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title=""></iframe></div>')

      // youtube iframe
      body = body.replace(/<p>https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9-_]+)/g,
          '<p><div class="youtube-frame"><iframe src="https://www.youtube.com/embed/$1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>')
      body = body.replace(/<p>https:\/\/youtu\.be\/([a-zA-Z0-9-_]+)/g,
          '<p><div class="youtube-frame"><iframe src="https://www.youtube.com/embed/$1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>')
      // link
      body = body.replace(/<p>((https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+))<\/p>/g,
          "<p><a href='$1' target='_blank'>$1</a></p>");
      // console.log(body)

      // query
      body = this.queryMarker(body, query)

      return body
    },

    // vimeoの再生をトリガとして、再生したかの情報を保存する
    vimeoPlayTrigger(post) {
      // 投稿内容
      let data = []
      const vimeo_ids = this.bodyEssence(post.body).match(/id="vimeo-[0-9a-z]+"/gi);
      if (vimeo_ids) {
        vimeo_ids.forEach((_v) => {
          data.push({sns_post_id: post.id, sns_post_file_id: null, vimeo_id: Number(_v.substring(10, _v.length - 1))})
        });
      }
      // 動画添付ファイル
      post.files.forEach((file) => {
        if (file.content_type === 'content_type_video') {
          data.push({
            sns_post_id: post.id,
            sns_post_file_id: file.id,
            vimeo_id: Number(file.video_tag.match(/video\/([0-9]+)/)[1])
          })
        }
      });

      data.forEach((_data) => {
        let played = false
        const key = `vimeo-${_data.vimeo_id}`
        const key_play_seconds = `${key}-play-seconds`
        const vimeo = new Vimeo.Player(key)
        // seek played seconds
        const play_seconds = this.$cookie.get(key_play_seconds)
        if (play_seconds) {
          vimeo.setCurrentTime(play_seconds)
        }

        vimeo.on('timeupdate', (e) => {
          // save played seconds
          this.$cookie.set(key_play_seconds, e.seconds - 5 > 0 ? e.seconds - 5 : 0)
          // console.log(`vimeo timeupdate(watching): vimeo_id: ${_data.vimeo_id}, post id: ${_data.sns_post_id}, post file id: ${_data.sns_post_file_id}`, e);
          if (!played && (e.percent >= 0.6 || (e.seconds/60) >= 30)) {
            console.log(`vimeo timeupdate(played): vimeo_id: ${_data.vimeo_id}, post id: ${_data.sns_post_id}, post file id: ${_data.sns_post_file_id}`, e);
            played = true
            _data['status'] = 'played'
            this.axios
                .post(`${this.env.api_base_url}sns/vimeo_play.json`, _data)
                .then(() => {
                })
                .catch((response) => {
                  console.log(response)
                })
                .finally(() => {
                })
          }
        })
        vimeo.on('play', (e) => {
          console.log(`vimeo play: vimeo_id: ${_data.vimeo_id}, post id: ${_data.sns_post_id}, post file id: ${_data.sns_post_file_id}`, e);
        });
      });
    },

    // 取得したデータにfrontで必要な変数をマージする
    addFrontParams(data) {
      data.posts.forEach((post) => {
        post.show_like_list = false
        post.height_limit = null
      });
    },

    // 投稿の要素の高さを調べる
    checkPostBodyHeight(posts) {
      for (let i = 0; i < posts.length; i++) {
        const post_id = posts[i].id
        const element = document.getElementById(`post-body-${post_id}`);
        if (element && posts[i].height_limit === null) {
          posts[i].height_limit = element.clientHeight > 250 ? 1 : 0
          // console.log(`post-body-${post_id}: clientHeight: ${element.clientHeight},  height_limit: ${posts[i].height_limit}`)
        }
      }
    },

    // いいね トグル
    likeUserPost(post) {
      this.axios
          .post(`${this.env.api_base_url}sns/post_like.json`, {
            id: post.id
          })
          .then(response => {
            post.like_user = response.data.like_user
            post.like_user_count = response.data.like_user_count
            post.like_users = null
          })
          .catch((response) => {
            console.log(response)
          })
    },

    // 投稿の更新
    savePost(post, save_post) {
      post.edit_mode = false
      if (post.sns_space_room_id !== save_post.sns_space_room_id || post.pinning !== save_post.pinning) {
        // スペース・ルームの移動を検出
        this.$router.go()
      } else {
        post.title = save_post.title
        post.body = save_post.body
        post.published_at = save_post.published_at
        post.last_updated_at = save_post.last_updated_at
        post.status = save_post.status
        post.sns_tag = save_post.sns_tag
        post.pinning = save_post.pinning

        post.files = save_post.files
        this.scrollTo(`#post-${post.id}`)
      }
    },

    // 投稿の編集開始
    editPost(post) {
      post.show_menu = false

      if (!post.editable) {
        alert('編集できない');
        return
      }
      post.edit_mode = !post.edit_mode
      if (post.edit_mode) {
        this.scrollTo(`#post-${post.id}`)
      }
    },
    // 投稿の通報
    reportPost(post) {
      post.show_menu = false
      if (!confirm('投稿を通報しますか？')) {
        return
      }

      this.axios
          .post(`${this.env.api_base_url}sns/report_post.json`, {
            id: post.id,
          })
          .then(() => {
            //window.$(`#post-${post.id}`).hide(300)
            alert('投稿を通報しました。');
          })
          .catch((response) => {
            console.log(response)
          })
          .finally(() => {
          })
    },
    // 投稿の削除
    deletePost(post) {
      post.show_menu = false
      if (!confirm('投稿を削除しますか？')) {
        return
      }

      this.axios
          .delete(`${this.env.api_base_url}sns/remove_post.json`, {
            params: {
              id: post.id,
            }
          })
          .then(() => {
            window.$(`#post-${post.id}`).hide(300)
            window.$(`#feed-${post.id}`).hide(300)
            /*
            this.posts.forEach((_post, index) => {
              if (post.id === _post.id) {
                this.posts.splice(index, 1);
              }
            });
            */
          })
          .catch((response) => {
            console.log(response)
          })
          .finally(() => {
          })
    },

    // 投稿の非表示
    hidePost(post) {
      post.show_menu = false
      if (!confirm('投稿を非表示にしますか？')) {
        return
      }

      this.axios
          .post(`${this.env.api_base_url}sns/hide_post.json`, {
            id: post.id,
          })
          .then(() => {
            window.$(`#post-${post.id}`).hide(300)
            window.$(`#feed-${post.id}`).hide(300)
            /*
            this.posts.forEach((_post, index) => {
              if (post.id === _post.id) {
                this.posts.splice(index, 1);
              }
            });
            */
          })
          .catch((response) => {
            console.log(response)
          })
          .finally(() => {
          })
    },

    playedTest(post) {
      this.axios
          .post(`${this.env.api_base_url}sns/vimeo_play.json`, {
            sns_post_id: post.id, sns_post_file_id: null, vimeo_id: 0, status: 'played'
          })
          .then(() => {
          })
          .catch((response) => {
            console.log(response)
          })
          .finally(() => {
          })
    },

    audioPlayedTest(post) {
      this.axios
          .post(`${this.env.api_base_url}sns/audio_play.json`, {
            sns_post_id: post.id,
          })
          .catch((response) => {
            console.log(response)
          })
    },

    // コメントの参照
    fetchComments(post) {
      if (post.comments.length >= post.comment_count) return

      post.comment_page += 1
      this.axios
          .get(`${this.env.api_base_url}sns/comments.json`, {
            params: {
              id: post.id,
              page: post.comment_page,
            }
          })
          .then(response => {
            //console.log(response.data)
            if (post.comment_page === 1) {
              post.comments = response.data.comments
            } else {
              post.comments = post.comments.concat(response.data.comments)
            }
          })
          .catch((response) => {
            console.log(response)
          })
          .finally(() => {
          })
    },

    // コメントの いいね トグル
    likeUserComment(comment) {
      this.axios
          .post(`${this.env.api_base_url}sns/comment_like.json`, {
            id: comment.id
          })
          .then(response => {
            comment.like_user = response.data.like_user
            comment.like_user_count = response.data.like_user_count
          })
          .catch((response) => {
            console.log(response)
          })
          .finally(() => {
          })
    },

    // コメントの投稿フォームの表示・非表示
    togglePostCommentForm(post) {
      const edit_mode = this.new_comment.sns_post_id === post.id && this.new_comment.edit_mode ? !this.new_comment.edit_mode : true
      this.new_comment = {
        sns_post_id: post.id,
        body: null,
        status: "status_published",
        files: [],
        edit_mode: edit_mode
      }
    },

    // コメントの新規追加
    newComment(post, comment, save_comment) {
      comment.edit_mode = false
      post.comments.push(save_comment)
      post.comment_count++
      // this.scrollTo(`#comment-${save_comment.id}`)
    },

    // コメントの更新
    saveComment(post, comment, save_comment) {
      comment.edit_mode = false
      comment.body = save_comment.body
      comment.updated_at = save_comment.updated_at
      comment.files = save_comment.files
      this.scrollTo(`#comment-${comment.id}`)
    },

    // コメントの編集
    editComment(comment) {
      comment.show_menu = false

      if (!comment.editable) {
        alert('編集できない');
        return
      }
      comment.edit_mode = !comment.edit_mode
      if (comment.edit_mode) {
        this.scrollTo(`#comment-${comment.id}`)
      }
    },
    // コメントの通報
    reportComment(post, comment) {
      comment.show_menu = false
      if (!confirm('このコメントを通報しますか？')) {
        return
      }

      this.axios
          .post(`${this.env.api_base_url}sns/report_comment.json`, {
            id: comment.id,
          })
          .then(() => {
            alert('コメントを通報しました。');
            /*
            post.comment_count -= 1
            post.comments.forEach((_comment, index) => {
              if (comment.id === _comment.id) {
                post.comments.splice(index, 1);
              }
            })
            */
          })
          .catch((response) => {
            console.log(response)
          })
          .finally(() => {
          })
    },
    // コメントの削除
    deleteComment(post, comment) {
      comment.show_menu = false
      if (!confirm('このコメントを削除しますか？')) {
        return
      }
      this.axios
          .delete(`${this.env.api_base_url}sns/remove_comment.json`, {
            params: {
              id: comment.id,
            }
          })
          .then(() => {
            post.comment_count -= 1
            post.comments.forEach((_comment, index) => {
              if (comment.id === _comment.id) {
                post.comments.splice(index, 1);
              }
            })
          })
          .catch((response) => {
            console.log(response)
          })
          .finally(() => {
          })
    },

    // フォロ
    follow(user) {
      this.axios
          .post(`${this.env.api_base_url}sns/follow.json`, {
            user_id: user.id
          })
          .then(() => {
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },

    // フォロー解除
    unFollow(user) {
      this.axios
          .post(`${this.env.api_base_url}sns/un_follow.json`, {
            user_id: user.id
          })
          .then(() => {
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },

    likeOver(post) {
      post.show_like_list = true
      // console.log('likeOver', post)
    },
    likeLeave(post) {
      post.show_like_list = false
      // console.log('likeLeave', post)
    },
    likeClick(post) {
      post.show_like_list = false
      post.show_like_modal = true
      // console.log('likeClick', post)
    },
    likeModalClose(post) {
      post.show_like_list = false
      post.show_like_modal = false
    },

    commentLikeOver(comment) {
      comment.show_like_list = true
      // console.log('likeOver', comment)
    },
    commentLikeLeave(comment) {
      comment.show_like_list = false
      // console.log('likeLeave', comment)
    },
    commentLikeClick(comment) {
      comment.show_like_list = false
      comment.show_like_modal = true
      // console.log('likeClick', comment)
    },
    commentLikeModalClose(comment) {
      comment.show_like_list = false
      comment.show_like_modal = false
    },

    // spaceのURL
    space_url(post) {
      if (post.space_category === 'category_timeline') {
        return {name: 'SnsTimeline', params: {user_id: post.user.id}}
      } else {
        const space_id = post.sns_space_id
        const room_id = post.sns_space_room_id === null ? 0 : post.sns_space_room_id
        const nav_category = post.space_category.substring(9)
        return {name: 'SnsSpace', params: {nav_category: nav_category, space_id: space_id, room_id: room_id}}
      }
    },

    // お気に入り
    favoritePost(post, is_confirm=false) {
      if (is_confirm && post.favorite && !confirm('お気に入りを解除しますか？')) {
        return
      }
      this.axios
          .post(`${this.env.api_base_url}sns/favorite_post.json`, {
            id: post.id
          })
          .then(() => {
            post.favorite = !post.favorite
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },
  }
}
</script>
