<template v-if="object && object.like_users">
  <div class="nice_list open" style="display: block;">
    <p v-for="user in object.like_users.users.slice(0,10)" :key="user.id">{{ user.sns_user_name }}</p>
    <p class="mt5" v-if="object.like_users.count > 10">他{{ object.like_users.count - 10 }}人が「いいね！」<br>と言っています。</p>
    <p class="mt5" v-else>{{ object.like_users.count }}人が「いいね！」<br>と言っています。</p>
  </div>
</template>

<script>
import Libraries from "@/components/Libraries";

export default {
  mixins: [Libraries],
  data() {
    return {
      object: this.post ? this.post : this.comment
    }
  },
  props: {
    post: null,
    comment: null
  },
  mounted() {
    if (this.object.like_user_count) {
      const uri = this.post ? 'like_users' : 'comment_like_users'
      this.axios
          .get(`${this.env.api_base_url}sns/${uri}.json`, {
            params: {id: this.object.id}
          })
          .then(response => {
            //console.log(response.data)
            this.object.like_users = response.data
          })
          .catch((response) => {
            console.log(response)
          })
          .finally(() => {
          })
    }
  }
}
</script>
