<template>
  <div class="cts_box search move-room-list" v-if="spaces">
    <h3>移動先：</h3>
    <div v-for="space in spaces" :key="space.id">
      <div class="space-name">{{ space.title }}</div>
      <div class="space-rooms">
              <span class="space-room" v-for="room in space.rooms" :key="room.id">
                <span class="radio">
                  <input v-bind:id="'room-' + room.id" type="radio"
                         v-model="move_room_id" name="move-id"
                         v-bind:value="room.id"
                         v-on:change="$emit('change', move_room_id)">
                  <label v-bind:for="'room-' + room.id">{{ room.title }}</label>
                </span>
              </span>
      </div>
    </div>
  </div>
</template>

<script>

import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  data() {
    return {
      spaces: null,
      move_room_id: this.room_id
    }
  },
  props: {
    room_id: null
  },
  mounted() {
    this.movePost()
  },
  methods: {
    movePost() {
      this.axios
          .get(`${this.env.api_base_url}sns/posts_digest_content.json`)
          .then(response => {
            this.spaces = response.data.navi_categories
          })
          .catch((response) => {
            this.errorAjax(response)
          })
    }
  }
}
</script>

<style lang="scss">
.move-room-list {
  .space-name {
    margin: 5px 0 0 10px;
    font-weight: bold;
  }

  .space-rooms {
    margin-left: 40px;
  }
}
</style>
