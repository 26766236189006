<template>
  <div v-if="object.like_users && object.like_users.count" class="user_search modal open">
    <div class="modal_box">
      <div class="header"><h4>「いいね」した人</h4>
        <div class="close" @click="$emit('close')"></div>
      </div>
      <div class="inner">
        <div class="user_search_list">
          <div class="user_box flex" v-for="user in object.like_users.users" :key="user.id">
            <div class="icon">
              <router-link :to="{ name: 'SnsTimeline', params: {user_id: user.id}}">
                <img v-bind:src="user.profile_image_url" alt="">
              </router-link>
            </div>
            <div class="name">{{ user.sns_user_name }}</div>
            <div class="btn">
              <template v-if="user.id !== current_user.id">
              <div class="follow_btn" v-if="!user.follow" @click="$emit('follow', user) ; user.follow = true">フォローする</div>
              <div class="follow_btn active" v-else @click="$emit('un_follow', user) ; user.follow = false">フォロー解除</div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ovr"></div>
  </div>

</template>

<script>
import Libraries from "@/components/Libraries";

export default {
  mixins: [Libraries],
  data() {
    return {
      object: this.post ? this.post : this.comment
    }
  },
  props: {
    post: null,
    comment: null
  },
  mounted() {
    if (this.object.like_user_count && !this.object.like_users) {
      const uri = this.post ? 'like_users' : 'comment_like_users'
      this.axios
          .get(`${this.env.api_base_url}sns/${uri}.json`, {
            params: {id: this.object.id}
          })
          .then(response => {
            //console.log(response.data)
            this.object.like_users = response.data
          })
          .catch((response) => {
            console.log(response)
          })
          .finally(() => {
          })
    }
  }
}
</script>
