<template>
  <div class="menu_box">
    <div class="menu_btn" v-on:click="comment.show_menu = !comment.show_menu"></div>
    <ul class="feed_menu" v-bind:class="{open: comment.show_menu}">
      <li v-if="comment.editable"><a href="javascript:void(0)" v-on:click="$emit('editComment')">編集する</a></li>
      <li><a href="javascript:void(0)" v-on:click="$emit('reportComment')">通報する</a></li>
      <li v-if="comment.editable"><a href="javascript:void(0)" v-on:click="$emit('deleteComment')">コメントを削除</a></li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    comment: null,
  }
}
</script>
