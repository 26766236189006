<template>
  <div>
    <div class="ogp" v-for="ogp in ogp_tags" :key="ogp.id" v-bind:id="'ogp-' + ogp.id">
      <a v-bind:href="ogp.ogp_url" target="_blank" class="ogp_link flex">
        <div class="thumb" v-if="ogp.ogp_image">
          <img v-bind:src="ogp.ogp_image" alt="">
        </div>
        <div class="ogp_cts">
          <div class="ogp_url">{{ ogp.ogp_url }}</div>
          <div class="ogp_ttl">{{ ogp.ogp_title }}</div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ogp_tags: null,
  }
}
</script>
