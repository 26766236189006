<template>
  <div class="tags">
    <label>ハッシュタグ</label>
    <ul class="inverse tagIt">
      <li v-for="tag in (tag_string.split(' '))" :key="tag">
        <a>{{ tag }}</a>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  data() {
    return {
      tag_string: this.sns_tag
    }
  },
  props: {
    sns_tag: null,
    tags: []
  },
  mounted() {
    window.$('.tagIt').tagit({
      placeholderText: "",
      fieldName: 'sns_tag',
      singleFieldDelimiter: ' ',
      singleField: true,
      readOnly: false,
      availableTags: this.tags,
      preprocessTag: ((tagLabel) => {
        if (!tagLabel) return tagLabel;
        tagLabel = '#' + tagLabel.replace(/[#| ]/g, '');
        if (tagLabel === '#') return '';
        return tagLabel;
      }),
      afterTagAdded: (() => {
        this.$emit('changed', document.getElementsByName('sns_tag')[0].value)
      }),
      afterTagRemoved: (() => {
        this.$emit('changed', document.getElementsByName('sns_tag')[0].value)
      }),
    });
  },
  methods: {}
}
</script>

<style>
.ui-widget-content.ui-autocomplete-input {
  height: auto;
  line-height: normal;
}
</style>
